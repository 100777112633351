.App {
  text-align: center;
  background-color: #0b4e63;
  height: 100vh;
  overflow: hidden;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cover {
  animation: blurIn 20s;
  background-size: contain;
  height: 150px;
  width: 150px;
  margin-bottom: 20px;
}
@keyframes blurIn {
  0% {
    filter: blur(30px);
  }
  100% {
    filter: blur(0px);
  }
}

.Game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

@media only screen and (min-height: 768px) {
  .Cover {
    height: 500px;
    width: 500px;
  }
}

@media only screen and (max-width: 428px) {
  .Cover {
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: unset;
  }
  @keyframes blurIn {
    0% {
      filter: blur(50px);
    }
    100% {
      filter: blur(0px);
    }
  }

  .App {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
  }

  .Game {
    justify-content: space-between;
  }
}

span {
  color: white;
}

h1,
h2,
h3,
span {
  font-family: "Ruslan Display";
}

h1 {
  font-size: 40px;
  color: white;
}

.Timeline {
  align-self: stretch;
  flex-direction: row;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .Timeline {
    flex-direction: column;
    display: block;
    position: relative;
    padding-left: 30vw;
    overflow: hidden;
    height: 300px;
  }
  .Timeline-List {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
  .Timeline-Cursor {
    position: absolute;
    left: 20px;
    top: -30px;
    width: 50%;
    z-index: 10;
    text-align: start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    color: white;
  }
  .Timeline-List-Spacer {
    scroll-snap-align: center;
    border-radius: unset;
    height: 60px;
    width: 100%;
  }

  .Timeline-LockButton {
    position: absolute;
    right: 20px;
    top: -25px;
    z-index: 10;
    height: 100%;
    align-items: center;
    display: flex;
  }
}

.Segment {
  flex: 1;
  height: 20px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0px 0px 4px 4px #0a9396;
  border-radius: 10px;
}

.Segment:hover {
  box-shadow: 0px 0px 4px 7px #0a9396;
}

.SongPin {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: #001219;
  box-shadow: 0px 0px 15px 14px rgba(0, 95, 115, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 10px;
  z-index: 0;
}

.SongPin-inner {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #666666;
  position: absolute;
  top: 35px;
  left: 35px;
  z-index: 1;
}

.SongPin > span {
  font-size: 25px;
  z-index: 2;
}

@media screen and (max-width: 600px) {
  .SongPin {
    scroll-snap-align: center;
    border-radius: unset;
    height: 60px;
    background-color: unset;
    box-shadow: unset;
  }

  .SongPin-inner {
    display: none;
  }
}


.MuteButton {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}